/* AuthForm.css */
@import '../../theme.css';

.auth-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
  box-sizing: border-box;
}

.auth-heading {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.auth-form-container {
  width: 100%;
  max-width: 1200px; /* Increased for plan selection */
  margin: 0 auto;
  transition: max-width 0.3s ease;
}

.auth-form-container.narrow {
  max-width: 400px;
}

.auth-form {
  width: 100%;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.auth-form input {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

.auth-form input:focus {
  border-color: #0070f3;
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 112, 243, 0.2);
}

.auth-form button {
  width: 100%;
  padding: 12px;
  background-color: #0070f3;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.auth-form button:hover {
  background-color: #0051bb;
}

.auth-form button.secondary {
  background-color: #f5f5f5;
  color: #333;
}

.auth-form button.secondary:hover {
  background-color: #e5e5e5;
}

.back-button {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  margin-bottom: 16px;
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
  font-size: 16px;
  transition: color 0.3s ease;
}

.back-button:hover {
  color: #333;
}

.back-button svg {
  margin-right: 8px;
}

.step-indicator {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.step-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ddd;
  margin: 0 4px;
  transition: background-color 0.3s ease;
}

.step-dot.active {
  background-color: #0070f3;
}

/* Plan Selection Styles */
.plans-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 24px;
  margin-top: 24px;
}

.plan-card {
  background: white;
  border-radius: 12px;
  padding: 24px;
  transition: all 0.3s ease;
  border: 2px solid transparent;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.plan-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
}

.plan-card.selected {
  border-color: #0070f3;
}

.plan-name {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 12px;
}

.plan-price {
  font-size: 2.5rem;
  font-weight: 700;
  color: #0070f3;
  margin-bottom: 16px;
}

.plan-features {
  list-style: none;
  padding: 0;
  margin: 0 0 24px 0;
}

.plan-features li {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  color: #666;
}

.plan-features li svg {
  margin-right: 8px;
  color: #0070f3;
}

.signup-text {
  margin-top: 20px;
  font-size: 14px;
  color: #666;
  text-align: center;
}

.signup-text a {
  color: #0070f3;
  text-decoration: none;
}

.signup-text a:hover {
  text-decoration: underline;
}

.error-text {
  color: #d32f2f;
  font-size: 14px;
  margin-top: 4px;
  margin-bottom: 16px;
}

@media (max-width: 768px) {
  .auth-form-container {
    padding: 16px;
  }

  .plans-grid {
    grid-template-columns: 1fr;
  }
}