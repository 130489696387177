.editable-text-container {
  padding-bottom: 20px;
  /* Adjust the padding as needed */
}

.dropzone-container {
  margin-top: 20px;
  padding: 20px;
  border: 2px dashed #ccc;
  border-radius: 5px;
  text-align: center;
  background-color: #fafafa;
  cursor: pointer;
}

.dropzone-container:hover {
  background-color: #f1f1f1;
}

.icon-button {
  color: black;
}

/* Remove the separate scroll from the media-container */
.media-container {
  padding-bottom: 80px; /* Ensure padding so the last item doesn't get hidden */
  overflow-y: visible; /* Let the page handle the scrolling */
  max-height: unset; /* Allow the media container to expand naturally */
}

/* Adjust the load-more-container */
.load-more-container {
  margin-top: 16px;
  padding-bottom: 80px; /* Extra padding to ensure visibility above the bottom bar */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Mobile-specific adjustments */
@media (max-width: 600px) {
  .media-container {
    padding-bottom: 120px; /* Ensure enough space for the bottom bar */
  }

  .load-more-container {
    padding-bottom: 120px; /* Match the padding so the button is visible */
  }
}

/* Adjust the PhotoGallery to take full width and let the page scroll */
.photo-gallery {
  width: 100%;
}