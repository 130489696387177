.generic-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: -webkit-fill-available;
  /* Fix for iOS Safari */
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  /* Prevent double scroll */
}

.main-content>.top-bar {
  flex-shrink: 0;
}

.content-area {
  flex: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
  padding: 24px;
}

/* Fix for iOS Safari bottom bar overlap */
@supports (-webkit-touch-callout: none) {
  .content-area {
    padding-bottom: calc(96px + env(safe-area-inset-bottom, 0px));
  }
}