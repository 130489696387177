@import '../../../theme.css';

/* Style for the BottomNavigation component */
.bottom-navigation {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000; /* Ensure it's above other content */
  background-color: var(--sidebar-bg-color); /* Use your theme's background color */
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); /* Optional shadow for better visibility */
}

/* Adjust the icon and label color */
.MuiBottomNavigationAction-root {
  color: grey !important;
}

.Mui-selected {
  color: var(--primary-color) !important; /* Highlight the selected item */
}
