/* DashboardPage.css */
.headerWithButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tableInputContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
}

@media (max-width: 600px) {
  .headerWithButton {
    flex-direction: column;
    align-items: flex-start;
  }
}

.icon-button {
  color: black;
}

