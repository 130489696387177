/* theme.css */

/* Colors and Fonts defined inside :root to make them globally available */
:root {
  --sidebar-bg-color: #fafafa;
  --topbar-bg-color: #ffffff;
  --text-color: #ecf0f1;
  --link-color: #ecf0f1;
  --border-color: #333;

  --main-font: 'Arial', sans-serif;
}