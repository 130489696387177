.galleriesGrid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  padding-bottom: 96px;
  /* Increased padding to account for bottom bar */
  margin-bottom: env(safe-area-inset-bottom, 0px);
  /* Handle iOS safe area */
}

@media (min-width: 600px) {
  .galleriesGrid {
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 80px;
    /* Slightly less padding needed on desktop */
  }
}

@media (min-width: 960px) {
  .galleriesGrid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.card {
  position: relative;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  cursor: pointer;
  overflow: hidden;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.previewGrid {
  position: relative;
  height: 200px;
  background-color: #f5f5f5;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 0;
  overflow: hidden;
}

.previewItem {
  position: relative;
  background-color: #e0e0e0;
  overflow: hidden;
}

.previewItem.single {
  grid-column: span 2;
  grid-row: span 2;
}

.previewItem.double {
  grid-row: span 2;
}

.previewImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.previewItem:hover .previewImage {
  transform: scale(1.05);
}

.emptyPreview {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  border-radius: 8px;
  grid-column: 1 / -1;
  /* Span all columns */
  grid-row: 1 / -1;
  /* Span all rows */
}

.cardContent {
  padding: 16px;
}

.galleryName {
  font-size: 1.125rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.metadataContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.timestamp {
  display: flex;
  align-items: center;
  color: #666;
  font-size: 0.875rem;
}

.timestampIcon {
  margin-right: 4px;
  font-size: 1rem !important;
}

.itemCount {
  color: #666;
  font-size: 0.875rem;
}

.deleteButton {
  position: absolute !important;
  top: 8px;
  right: 8px;
  background-color: rgba(255, 255, 255, 0.9) !important;
  opacity: 0;
  transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out !important;
}

.card:hover .deleteButton {
  opacity: 1;
}

.deleteButton:hover {
  background-color: rgba(255, 255, 255, 1) !important;
}

/* Loading animation styles */
.loadingCard {
  pointer-events: none;
}

.loadingPreview {
  height: 200px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

.loadingContent {
  padding: 16px;
}

.loadingTitle {
  height: 24px;
  width: 75%;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  margin-bottom: 12px;
  border-radius: 4px;
}

.loadingMeta {
  height: 16px;
  width: 50%;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  border-radius: 4px;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}