@page {
  size: A4 portrait;
  margin: 0;
}

.printable-page {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
