@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    margin: 0;
    font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--sidebar-bg-color);
  }

  code {
    font-family: 'Lato', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
}

/* Define CSS variables for theme colors */
:root {
  --sidebar-bg-color: #fafafa;
  --topbar-bg-color: #fafafa;
  --text-color: #ecf0f1;
  --link-color: #ecf0f1;
  --border-color: #333;
  --main-font: 'Lato', sans-serif;
}