/* src/components/Common/TopBar.css */

.top-bar {
  height: auto;
  min-height: 80px;
  background-color: var(--topbar-bg-color);
  color: black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  border-bottom: 1px solid lightgray;
}

.top-bar-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.top-bar-title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-bar-description {
  text-align: center;
  font-size: 14px;
  color: #666;
  max-width: 600px;
  margin-top: 4px;
  white-space: pre-line;
}

.top-bar-actions {
  display: flex;
  align-items: center;
}

.icon-button {
  color: black;
}

@media (max-width: 600px) {
  .top-bar {
    padding: 12px 16px;
  }

  .top-bar-description {
    font-size: 12px;
    max-width: 100%;
  }
}