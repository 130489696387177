.editable-text-container {
  padding-bottom: 20px;
  /* Adjust the padding as needed */
}

.modal-content {
  background-color: #f5f5f5;
}

.modal-actions {
  background-color: #f5f5f5;
}

.icon-button {
  color: black;
}

.icon-button-gray {
  color: gray;
}

