.gallery__item {
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.gallery__item:hover {
  transform: scale(1.05);
}

/* Container for each media item in the gallery */
.gallery__item-container {
  position: relative;
}

/* Positioning the selection icon */
.select-icon-container {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 2;
}

.select-icon {
  background-color: rgba(255, 255, 255, 0.7);
}

/* Container for video thumbnails */
.video-thumbnail-container {
  position: relative;
  display: inline-block;
}

/* Icon overlay on video thumbnails */
.play-icon-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 48px;
  color: white;
  opacity: 0.8;
}

/* Media Gallery responsive adjustments */
@media (max-width: 600px) {
  .gallery__item-container {
    width: 100%;
  }
}